<h1 mat-dialog-title>Ayuda</h1>
<div mat-dialog-content>
  <p>El mapa de riesgos tiene el objetivo de identificar procesos o actividades que poseen un riesgo inherente, y cuantificarlos en función de la frecuencia con la que pueden ocurrir y el impacto económico que pueden provocar.</p>
  <p>La aplicación se presenta como un primer acercamiento a los mapas de riesgos. Con el objeto de facilitar su elaboración, el usuario encontrará, clasificado por categorías, un inventario de riesgos que se podrían considerar comunes del tejido empresarial. Así, para cada riesgo propuesto, solamente se deberá seleccionar la frecuencia/probabilidad con la que el riesgo puede materializarse, y el impacto económico asociado.</p>
  <p>Con el fin de facilitar la interpretación posterior del mapa, se propone al usuario tomar las siguientes referencias de frecuencia y de impacto:</p>
  <div class="flex f-fd--column-md f-gap--10px">
    <div class="f-basis--8-lg f-basis--12-md table-container">
      <h4>Frecuencia / Probabilidad</h4>
      <table mat-table [dataSource]="dataSourceProbabilidad" class="table">
        <ng-container matColumnDef="clasification">
          <th mat-header-cell *matHeaderCellDef>Clasificación</th>
          <td mat-cell *matCellDef="let element">{{ element.clasification }}</td>
        </ng-container>
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="text-center">{{ element.value }}</td>
        </ng-container>
        <ng-container matColumnDef="frequency">
          <th mat-header-cell *matHeaderCellDef>Frecuencia</th>
          <td mat-cell *matCellDef="let element">{{ element.frequency }}</td>
        </ng-container>
        <ng-container matColumnDef="example">
          <th mat-header-cell *matHeaderCellDef>Ejemplo</th>
          <td mat-cell *matCellDef="let element">{{ element.example }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsProbabilidad"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsProbabilidad"></tr>
      </table>
    </div>
    <div class="f-basis--4-lg f-basis--12-md table-container">
      <h4>Impacto</h4>
      <table mat-table [dataSource]="dataSourceImpacto" class="table">
        <ng-container matColumnDef="clasification">
          <th mat-header-cell *matHeaderCellDef>Clasificación</th>
          <td mat-cell *matCellDef="let element">{{ element.clasification }}</td>
        </ng-container>
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="text-center">{{ element.value }}</td>
        </ng-container>
        <ng-container matColumnDef="quantification">
          <th mat-header-cell *matHeaderCellDef>Cuantificación</th>
          <td mat-cell *matCellDef="let element">{{ element.quantification }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsImpacto"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsImpacto"></tr>
      </table>
    </div>
  </div>
  <p>&nbsp;</p>
  <p>Es posible que no todos los riesgos inventariados sean de aplicación a todas las empresas. En los casos en los que el usuario considere que el riesgo no le aplica (no tiene exposición ni impacto significante), se puede descartar. Igualmente, el usuario tiene la posibilidad de incluir otros riesgos no inventariados en la propuesta inicial.</p>
  <p>Habitualmente todas las empresas tienen implantados procedimientos o herramientas de tratamiento del riesgo como pueden ser pólizas de seguros, transferencia contractual de riesgos a proveedores, etc. Para la elaboración del mapa de riesgos proponemos analizar el impacto sin considerar estas herramientas. La siguiente fase en gerencia de riesgos sería el tratamiento del riesgo donde entraría el estudio de estas y otras herramientas.</p>
  <p>
    <b>En caso de interrupción en la sesión, el usuario podrá completar la elaboración de su mapa de riesgos en varias sesiones ya que la herramienta guarda automáticamente los avances realizados.</b>
  </p>
  <p>Una vez finalizado el ejercicio, la aplicación generará un informe de conclusiones, donde se presentará su evaluación preliminar de riesgos y algunas de las posibilidades de tratamiento que se abren una vez se identifican los riesgos a los que tiene mayor exposición.</p>
  <p>Como indicamos, éste no es más que un acercamiento inicial a la elaboración de su mapa de riesgos. Una vez elaborado, el equipo de Howden se pone a su disposición para analizarlo conjuntamente y profundizar en su desarrollo y/o establecer iniciativas para el tratamiento de sus riesgos.</p>
</div>
<div mat-dialog-actions class="btn-container flex f-ai--center f-jc--center">
  <button mat-raised-button mat-dialog-close>Cerrar</button>
</div>
