import { IMap } from 'src/app/core/models/api';
import { IMapViewModel } from '../models';

export abstract class MapMapper {
  static mapForSearch(source: IMap): IMapViewModel {
    return {
      mapId: source.mapId,
      businessName: source.businessName,
      fiscalCode: source.fiscalCode,
      contactEmail: source.contactEmail,
      creatorUser: source.creatorUser,
      creatorDate: source.creatorDate
    } as IMapViewModel;
  }
}
