import { Component } from '@angular/core';
import { IHelpImpactElement, IHelpProbabilityElement } from 'src/app/core/models/front';
import { IHelpDataService } from 'src/app/core/services/core';

@Component({
  selector: 'howden-help-risk-map',
  templateUrl: './help-risk-map.component.html',
  styleUrls: ['./help-risk-map.component.scss']
})
export class HelpRiskMapComponent {
  public dataSourceProbabilidad: IHelpProbabilityElement[] = [];
  public displayedColumnsProbabilidad: string[] = ['clasification', 'value', 'frequency', 'example'];
  public dataSourceImpacto: IHelpImpactElement[] = [];
  public displayedColumnsImpacto: string[] = ['clasification', 'value', 'quantification'];

  constructor(private helpDataSrv: IHelpDataService) {
    this.dataSourceProbabilidad = this.helpDataSrv.getDataHelpProbability();
    this.dataSourceImpacto = this.helpDataSrv.getDataHelpImpact();
  }
}
