import { Component } from '@angular/core';
import { IHelpImpactElement } from 'src/app/core/models/front';
import { IHelpDataService } from 'src/app/core/services/core';

@Component({
  selector: 'howden-help-impact',
  templateUrl: './help-impact.component.html',
  styleUrls: ['./help-impact.component.scss']
})

export class HelpImpactComponent {
  public dataSource: IHelpImpactElement[] = [];
  public displayedColumns: string[] = ['clasification', 'value', 'quantification'];

  constructor(private helpDataSrv: IHelpDataService) {
    this.dataSource = this.helpDataSrv.getDataHelpImpact();
  }
}
