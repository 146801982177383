import { Injectable } from '@angular/core';
import { HowdenHttpClientService, IPageOf } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { API_PATH_APPS } from 'src/app/core/constants';
import { IMapRating, IMapRatingFilter } from 'src/app/core/models/api';
import { IMapRatingService } from '.';

@Injectable({
  providedIn: 'root'
})
export class MapRatingService implements IMapRatingService {
  apiName = API_PATH_APPS.API_RISK_MAP;
  basePath = 'api/maprating';

  constructor(private httpClient: HowdenHttpClientService) { }

  search(request: IMapRatingFilter): Observable<IPageOf<IMapRating>> {
    return this.httpClient.post<IPageOf<IMapRating>>(
      this.apiName,
      `${this.basePath}/search`,
      request
    );
  }

  downloadExcel(request: IMapRatingFilter): Observable<Blob> {
    return this.httpClient.postRespBlob(
      this.apiName,
      `${this.basePath}/download-excel`,
      request,
      {
        reportProgress: true,
        responseType: 'blob'
      }
    );
  }
}
