import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { HowdenAutocompleteModule } from '@howdeniberia/core-front';
import { NgxEchartsModule } from 'ngx-echarts';
import { CategoryService, ICategoryService } from 'src/app/core/services/api/category';
import { ILocalityService, LocalityService } from 'src/app/core/services/api/locality';
import { IMapService, MapService } from 'src/app/core/services/api/map';
import { IRiskService, RiskService } from 'src/app/core/services/api/risk';
import { IRisksDataMapService, RisksDataMapService } from 'src/app/core/services/core';
import { ConfirmationDialogService, IConfirmationDialogService, IUIBlockerService, UIBlockerService } from 'src/app/core/services/ui';
import { DownloadReportComponent } from './dialogs/pages/download-report/download-report.component';
import { EditRiskComponent } from './dialogs/pages/edit-risk/edit-risk.component';
import { RisksEvaluationComponent } from './pages/risks-evaluation/risks-evaluation.component';
import { RisksListComponent } from './pages/risks-list/risks-list.component';
import { RisksMapComponent } from './pages/risks-map/risks-map.component';
import { RisksSelectionComponent } from './pages/risks-selection/risks-selection.component';
import { RisksStepsComponent } from './pages/risks-steps/risks-steps.component';
import { RiskMapEvaluationRoutingModule } from './risk-map-evaluation-routing.module';

@NgModule({
  declarations: [
    EditRiskComponent,
    DownloadReportComponent,
    RisksEvaluationComponent,
    RisksListComponent,
    RisksMapComponent,
    RisksSelectionComponent,
    RisksStepsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatDialogModule,
    NgxEchartsModule,
    HowdenAutocompleteModule,
    RiskMapEvaluationRoutingModule
  ],
  providers: [
    { provide: IUIBlockerService, useClass: UIBlockerService },
    { provide: IConfirmationDialogService, useClass: ConfirmationDialogService },
    { provide: ICategoryService, useClass: CategoryService },
    { provide: IRiskService, useClass: RiskService },
    { provide: ILocalityService, useClass: LocalityService },
    { provide: IMapService, useClass: MapService },
    { provide: IRisksDataMapService, useClass: RisksDataMapService }
  ]
})
export class RiskMapEvaluationModule { }
