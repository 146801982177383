import { FormControl, FormGroup, Validators } from '@angular/forms';

export class EditRiskViewModel {
  private _form: FormGroup;

  readonly C_ID = 'riskId';
  readonly C_DESCRIPTION = 'description';

  constructor() {
    this._form = new FormGroup({
      [this.C_ID]: new FormControl(null),
      [this.C_DESCRIPTION]: new FormControl(null, [Validators.required])
    });
  }

  get form(): FormGroup {
    return this._form;
  }

  get riskId(): number | null | undefined {
    return this._form.get(this.C_ID)?.value;
  }
  set riskId(value: number | null | undefined) {
    this._form.get(this.C_ID)?.setValue(value);
  }

  get description(): string | null | undefined {
    return this._form.get(this.C_DESCRIPTION)?.value;
  }
  set description(value: string | null | undefined) {
    this._form.get(this.C_DESCRIPTION)?.setValue(value);
  }
}
