import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  GENERAL_CONSTANTS,
  HowdenMainLayoutComponent,
  HowdenWithoutMenuLayoutComponent,
  NOT_AUTHORIZED_ROUTING,
  NOT_FOUND_ROUTING,
  NotAuthorizedComponent
} from '@howdeniberia/core-front';
import { UserRole } from './core/enums';
import { HomePageModule } from './features/homepage/homepage.module';
import { InitialComponent } from './features/initial/initial/initial.component';
import { ManagementModule } from './features/management/management.module';
import { RiskMapEvaluationModule } from './features/risk-map-evaluation/risk-map-evaluation.module';

export const AppRoutes = [
  {
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    path: 'management',
    component: HowdenMainLayoutComponent,
    data: {
      isLayout: true,
      roles: [UserRole.Manager, UserRole.SuperAdmin]
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: InitialComponent
      },
      {
        path: '',
        loadChildren: () => HomePageModule
      },
      {
        path: '',
        loadChildren: () => RiskMapEvaluationModule
      },
      {
        path: '',
        loadChildren: () => ManagementModule
      }
    ]
  },
  {
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    path: '',
    component: HowdenWithoutMenuLayoutComponent,
    data: {
      isLayout: true,
      roles: [UserRole.Basic]
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: InitialComponent
      },
      {
        path: '',
        loadChildren: () => HomePageModule
      },
      {
        path: '',
        loadChildren: () => RiskMapEvaluationModule
      }
    ]
  },
  {
    path: GENERAL_CONSTANTS.NotAuthorizedUrl,
    title: $localize`:@@app.route.notauthorized: No autorizado`,
    component: NotAuthorizedComponent
  },
  NOT_AUTHORIZED_ROUTING,
  NOT_FOUND_ROUTING,
  { path: '**', redirectTo: '404' }
] as Routes;

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
