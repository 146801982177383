<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.filterForm">
    <div class="flex f-gap--10px margin-bottom-10">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.map.search.dateFrom">Fecha Desde</mat-label>
        <input matInput
          [matDatepicker]="dateFromPicker"
          [formControlName]="model.C_DATE_FROM"
          [howdenControlError]="dateFromError"
          [min]="minDate"
        />
        <mat-error #dateFromError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
        <mat-datepicker #dateFromPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.map.search.dateTo">Fecha Hasta</mat-label>
        <input matInput
          [matDatepicker]="dateToPicker"
          [formControlName]="model.C_DATE_TO"
          [howdenControlError]="dateToError"
          [min]="minDate"
        />
        <mat-error #dateToError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
        <mat-datepicker #dateToPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.map.search.fiscalCode">CIF</mat-label>
        <input matInput [formControlName]="model.C_FISCAL_CODE" maxlength="15"/>
      </mat-form-field>
    </div>
    <div class="flex f-gap--10px margin-bottom-10">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.map.search.businessName">Razón Social</mat-label>
        <input matInput [formControlName]="model.C_BUSINESS_NAME" maxlength="50"/>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.map.search.contactEmail">Email Contacto</mat-label>
        <input matInput [formControlName]="model.C_CONTACT_EMAIL" maxlength="50" />
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.map.search.creatorUser">Usuario Creador</mat-label>
        <input matInput [formControlName]="model.C_CREATOR_USER" maxlength="250"/>
      </mat-form-field>
    </div>
  </form>
  <div>
    <howden-table
      class="small-font"
      keyField="mapId"
      paginable="server"
      sortable="server"
      filterable="server"
      [cols]="model.columns"
      [data]="model.data"
      [currentPage]="model.searchRequest.pageNumber"
      [pageSize]="model.searchRequest.pageSize"
      [totalRows]="model.length"
      [buttons]="model.buttons"
      (pageChanged)="onServerSideConfigChanged($event)"
      (sortChanged)="onServerSideConfigChanged($event)"
      (buttonClicked)="onAction($event)"
      >
    </howden-table>
  </div>
</div>
