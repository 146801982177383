<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.filterForm">
    <div class="flex f-gap--10px margin-bottom-10">
      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.risk-rating.search.dateFrom">Fecha Desde</mat-label>
        <input matInput
          [matDatepicker]="dateFromPicker"
          [formControlName]="model.C_DATE_FROM"
          [howdenControlError]="dateFromError"
          [min]="minDate"
        />
        <mat-error #dateFromError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
        <mat-datepicker #dateFromPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.risk-rating.search.dateTo">Fecha Hasta</mat-label>
        <input matInput
          [matDatepicker]="dateToPicker"
          [formControlName]="model.C_DATE_TO"
          [howdenControlError]="dateToError"
          [min]="minDate"
        />
        <mat-error #dateToError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
        <mat-datepicker #dateToPicker></mat-datepicker>
      </mat-form-field>

     <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.risk-rating.search.category">Categoría</mat-label>
        <mat-select [formControlName]="model.C_CATEGORY_ID" [howdenControlError]="categoryIdError">
          <mat-option></mat-option>
          @for (item of model.categories | howdenArraySort : 'description' ; track item.categoryId) {
            <mat-option [value]="item.categoryId">
              {{ item.description }}
            </mat-option>
          }
        </mat-select>
        <mat-error #categoryIdError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.risk-rating.search.description">Riesgo</mat-label>
        <input matInput [formControlName]="model.C_DESCRIPTION" maxlength="50"/>
      </mat-form-field>
    </div>
    <div class="flex f-gap--10px margin-bottom-10">
      <div class="f-basis--9"></div>
      <div class="f-basis--3">
        <div class="flex f-jc--end">
          <button type="button"
            mat-raised-button
            color="primary"
            (click)="onDownloadExcel()">
            <span i18n="@@app.risk-rating.search.download-excel">Descargar Excel</span>
          </button>
        </div>
      </div>
    </div>
  </form>
  <div>
    <howden-table
      class="small-font"
      paginable="server"
      sortable="server"
      filterable="server"
      [clickableRow]="false"
      [cols]="model.columns"
      [data]="model.data"
      [currentPage]="model.searchRequest.pageNumber"
      [pageSize]="model.searchRequest.pageSize"
      [totalRows]="model.length"
      (pageChanged)="onServerSideConfigChanged($event)"
      (sortChanged)="onServerSideConfigChanged($event)"
      >
    </howden-table>
  </div>
</div>
