<h1 mat-dialog-title>Ejemplo</h1>
<div mat-dialog-content [ngClass]="{ noData: withExample === false }">
  @if (withExample) {
    <div>
      {{ example }}
    </div>
  } @else {
    No se ha facilitado ningún ejemplo
  }
  <ng-template #noData>No se ha facilitado ningún ejemplo</ng-template>
</div>
<div mat-dialog-actions class="btn-container flex f-ai--center f-jc--center">
  <button mat-raised-button mat-dialog-close>Cerrar</button>
</div>
