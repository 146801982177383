import { Component, OnDestroy, OnInit } from '@angular/core';
import { IPageOf, ITableEventData } from '@howdeniberia/core-front';
import { IMapRating } from 'src/app/core/models/api';
import { ICategoryService } from 'src/app/core/services/api/category';
import { IMapRatingService } from 'src/app/core/services/api/map';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { FileUtils, Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { RiskRatingMapper } from '../../mapper';
import { RiskRatingSearchViewModel } from '../../models';

@Component({
  selector: 'howden-risk-rating-search',
  templateUrl: './risk-rating-search.component.html',
  styleUrl: './risk-rating-search.component.scss'
})
export class RiskRatingSearchComponent implements OnInit, OnDestroy {
  model = new RiskRatingSearchViewModel();

  get minDate(): Date { return Limits.minDate; }

  private _subscriptions = new SubSink();

  constructor(
    private categorySrv: ICategoryService,
    private mapRatingSrv: IMapRatingService,
    private uiBlockerSrv: IUIBlockerService
  ) { }

  ngOnInit(): void {
    this.categorySrv.getCategories().subscribe((data) => {
      this.model.categories = data ?? [];
      this.refresh();
      this.onChanges();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onServerSideConfigChanged(event: ITableEventData): void {
    this.model.updateServerSideConfig(event);
    this.refresh();
  }

  onDownloadExcel(): void {
    this.uiBlockerSrv.block();

    this.model.updateServerSideFilters();

    this.mapRatingSrv.downloadExcel(this.model.searchRequest).subscribe(
      {
        next: (blob: Blob) => {
          const attachName = FileUtils.formatFilename('RiskRating', 'xlsx');
          FileUtils.downloadFile(
            attachName,
            blob
          );
        },
        complete: () => {
          this.uiBlockerSrv.unblock();
        }
      }
    );
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.filterForm.valueChanges.subscribe(() => {
      this.model.searchRequest.pageNumber = 0;
      this.model.updateServerSideFilters();
      this.refresh();
    });
  }

  private refresh(): void {
    this.model.updateServerSideFilters();

    this.uiBlockerSrv.block();

    this.mapRatingSrv.search(this.model.searchRequest).subscribe({
      next: (searchResult: IPageOf<IMapRating>) => {
        this.model.length = searchResult.totalCount;
        this.model.data = searchResult.data?.map((source) =>
          RiskRatingMapper.mapForSearch(source)
        );
      },
      complete: () => {
        this.uiBlockerSrv.unblock();
      }
    });
  }
}
