import { Injectable } from '@angular/core';
import { IHelpDataService } from '.';
import { IHelpImpactElement, IHelpProbabilityElement } from '../../models/front';

@Injectable({
  providedIn: 'root'
})
export class HelpDataService implements IHelpDataService {
  getDataHelpProbability(): IHelpProbabilityElement[] {
    const data: IHelpProbabilityElement[] = [
      {
        clasification: 'Muy Alta',
        value: '5',
        frequency: 'Ocurre una vez al año',
        example: 'Temporal por paso de TCA, DANA, impacto de vehículos o fallos en la calidad de un producto o servicio'
      },
      {
        clasification: 'Alta',
        value: '4',
        frequency: 'Ocurre una vez cada tres años',
        example: 'Infección de un PC con un virus o similar, robo en una instalación, pérdida de un móvil o multa de tráfico'
      },
      {
        clasification: 'Media',
        value: '3',
        frequency: 'Ocurre una vez cada 10 años',
        example: 'Bloqueo de carreteras principales por acumulación de nieve o huelga sectorial'
      },
      {
        clasification: 'Baja',
        value: '2',
        frequency: 'Ocurre una vez cada 50 años',
        example: 'Terremoto de fuerza 6 en territorio español'
      },
      {
        clasification: 'Muy Baja',
        value: '1',
        frequency: 'Ocurre una vez cada 100 años',
        example: 'Pandemia a nivel mundial'
      }
    ];
    return data;
  }

  getDataHelpImpact(): IHelpImpactElement[] {
    const data: IHelpImpactElement[] = [
      { clasification: 'Muy Alta', value: '5', quantification: 'Pérdidas superiores al 20% del EBITDA' },
      { clasification: 'Alta', value: '4', quantification: 'Pérdidas superiores al 5% del EBITDA' },
      { clasification: 'Media', value: '3', quantification: 'Pérdidas superiores al 1% del EBITDA' },
      { clasification: 'Baja', value: '2', quantification: 'Pérdidas superiores al 0,1% del EBITDA' },
      { clasification: 'Muy Baja', value: '1', quantification: 'Pérdidas inferiores al 0,05% del EBITDA' }
    ];

    return data;
  }
}
