import { Component, OnDestroy, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { IMapRiskInfo } from 'src/app/core/models/front';
import { IRisksDataMapService } from 'src/app/core/services/core';
import { SubSink } from 'subsink';

@Component({
  selector: 'howden-risks-map',
  templateUrl: './risks-map.component.html',
  styleUrls: ['./risks-map.component.scss']
})
export class RisksMapComponent implements OnInit, OnDestroy {
  public chartOptions!: EChartsOption;
  private _subscriptions = new SubSink();

  constructor(private dataRiskMapSrv: IRisksDataMapService) {
  }

  ngOnInit() {
    this._subscriptions.sink = this.dataRiskMapSrv.getDataRiskMapEmitter().subscribe((data: Array<IMapRiskInfo>) => {
      this.updateRiskMapChart(data);
    });

    this.updateRiskMapChart(new Array<IMapRiskInfo>());
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  updateRiskMapChart(dataRiskMap: Array<IMapRiskInfo>) {
    this.chartOptions = this.dataRiskMapSrv.getChartOptions(dataRiskMap);
  }
}
