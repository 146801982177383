import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { HelpDataService, IHelpDataService } from 'src/app/core/services/core';
import { HelpImpactComponent } from './dialogs/help-impact/help-impact.component';
import { HelpProbabilityComponent } from './dialogs/help-probability/help-probability.component';
import { HelpRiskMapComponent } from './dialogs/help-risk-map/help-risk-map.component';
import { HelpRiskComponent } from './dialogs/help-risk/help-risk.component';

@NgModule({
  declarations: [
    HelpImpactComponent,
    HelpProbabilityComponent,
    HelpRiskComponent,
    HelpRiskMapComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatTableModule
  ],

  providers: [
    { provide: IHelpDataService, useClass: HelpDataService }
  ]
})
export class HelpModule { }
