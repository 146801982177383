<form [formGroup]="model.form" (ngSubmit)="saveRisk()">
  <h1 mat-dialog-title i18n="@@app.risk-map.edit-risk.title">Editar Riesgo</h1>
  <div mat-dialog-content>
    <mat-form-field>
      <mat-label i18n="@@app.risk-map.edit-risk.description">Descripción</mat-label>
      <input matInput [formControlName]="model.C_DESCRIPTION" maxlength="250"  />
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="btn-container flex f-ai--center f-jc--center">
    <button mat-raised-button mat-dialog-close>Cancelar</button>
    <button mat-raised-button [disabled]="model.form.invalid">Guardar</button>
  </div>
</form>
