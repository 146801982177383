import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'howden-help-risk',
  templateUrl: './help-risk.component.html',
  styleUrls: ['./help-risk.component.scss']
})
export class HelpRiskComponent {
  public example = '';
  public withExample = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.example = data.risk.example;
      if (this.example === '') {
        this.withExample = false;
      } else {
        this.withExample = true;
      }
    }
  }
}
