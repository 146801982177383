import { Component } from '@angular/core';
import { IStep } from 'src/app/core/models/front';
import { IMapService } from 'src/app/core/services/api/map';
import { IRisksDataMapService } from 'src/app/core/services/core';
import { IUIBlockerService } from 'src/app/core/services/ui';

@Component({
  selector: 'howden-risks-steps',
  templateUrl: './risks-steps.component.html',
  styleUrls: ['./risks-steps.component.scss']
})
export class RisksStepsComponent {
  public steps: IStep[] = [];

  constructor(
    private mapSrv: IMapService,
    private uiBlockerSrv: IUIBlockerService,
    private dataRiskMapSrv: IRisksDataMapService
  ) {
  }

  loadSteps(mapToLoad: string | null) {
    this.uiBlockerSrv.block();
    this.mapSrv.getSteps(mapToLoad).subscribe((steps) => {
      this.steps = steps;
      this.uiBlockerSrv.unblock();
      if (mapToLoad !== null) {
        this.getMapData(mapToLoad);
      } else {
        this.checkUnfinishedMap();
      }
    });
  }

  checkFullFlow(): boolean {
    if (this.steps.length > 0) {
      if (this.steps.filter(x => x.complete).length === this.steps.length) {
        return true;
      }
    }
    return false;
  }

  private getMapData(mapId: string) {
    this.uiBlockerSrv.block();
    this.mapSrv.get(mapId).subscribe((mapData) => {
      this.uiBlockerSrv.unblock();
      this.dataRiskMapSrv.loadDataRiskMap(mapData);
    });
  }

  private checkUnfinishedMap() {
    this.uiBlockerSrv.block();
    this.mapSrv.getUnfinishedMap().subscribe((mapData) => {
      this.uiBlockerSrv.unblock();
      if (mapData && mapData.riskRatings.length > 0) {
        const question: string = $localize`: @@app.risks-evaluation.load-map-data.message: Hay datos almacenados de un mapa realizado anteriormente\r\n ¿Desea recuperarlos?`;
        if (confirm(question)) {
          this.dataRiskMapSrv.loadDataRiskMap(mapData);
        }
      }
    });
  }
}
