import { IRisk } from 'src/app/core/models/api';
import { EditRiskViewModel } from '../models';

export abstract class EditRiskMapper {
  static mapForEdit(source: IRisk, target: EditRiskViewModel): void {
    target.riskId = source.riskId;
    target.description = source.description;
  }

  static mapForUpdate(source: EditRiskViewModel): IRisk {
    return {
      riskId: source.riskId,
      description: source.description
    } as IRisk;
  }
}
