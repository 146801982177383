import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HowdenLabelValueModule, HowdenPageTitleModule, HowdenSharedModule, HowdenTableModule } from '@howdeniberia/core-front';
import { IMapRatingService, IMapService, MapRatingService, MapService } from 'src/app/core/services/api/map';
import { ConfirmationDialogService, IConfirmationDialogService, IUIBlockerService, UIBlockerService } from 'src/app/core/services/ui';
import { ManagementRoutingModule } from './management-routing.module';
import { MapSearchComponent } from './map-search/pages/map-search/map-search.component';
import { RiskRatingSearchComponent } from './risk-rating-search/pages/risk-rating-search/risk-rating-search.component';

@NgModule({
  declarations: [
    MapSearchComponent,
    RiskRatingSearchComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatTooltipModule,
    MatDatepickerModule,
    HowdenLabelValueModule,
    HowdenPageTitleModule,
    HowdenTableModule,
    HowdenSharedModule,
    ManagementRoutingModule
  ],
  providers: [
    { provide: IUIBlockerService, useClass: UIBlockerService },
    { provide: IConfirmationDialogService, useClass: ConfirmationDialogService },
    { provide: IMapService, useClass: MapService },
    { provide: IMapRatingService, useClass: MapRatingService }
  ]
})
export class ManagementModule { }
