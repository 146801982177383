import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ICookieManagerService } from '.';

@Injectable({
  providedIn: 'root'
})
export class CookieManagerService implements ICookieManagerService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly RISK_MAP_COOKIES_KEY: string = 'HowdenRiskMapCookies';

  constructor(private cookieService: CookieService) {
  }

  acceptCookies() {
    this.cookieService.set(this.RISK_MAP_COOKIES_KEY, 'Cookies Aceptadas');
  }

  deleteCookies() {
    this.cookieService.deleteAll();
  }

  cookiesNoticeAccepted(): boolean {
    return this.cookieService.check(this.RISK_MAP_COOKIES_KEY);
  }
}
