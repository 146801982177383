import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { DialogResultTypes, HowdenAlertService, IDialogResult } from '@howdeniberia/core-front';
import { tap } from 'rxjs';
import { IRiskRating } from 'src/app/core/models/api';
import { IStep } from 'src/app/core/models/front';
import { IRiskService } from 'src/app/core/services/api/risk';
import { IRisksDataMapService } from 'src/app/core/services/core';
import { IConfirmationDialogService, IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { HelpImpactComponent } from '../../../help/dialogs/help-impact/help-impact.component';
import { HelpProbabilityComponent } from '../../../help/dialogs/help-probability/help-probability.component';
import { HelpRiskComponent } from '../../../help/dialogs/help-risk/help-risk.component';
import { EditRiskComponent } from '../../dialogs/pages/edit-risk/edit-risk.component';


@Component({
  selector: 'howden-risks-list',
  templateUrl: './risks-list.component.html',
  styleUrls: ['./risks-list.component.scss']
})
export class RisksListComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['riskId', 'description', 'probability', 'impact', 'select', 'edit', 'delete'];
  risks: IRiskRating[] = [];
  dataSource = new MatTableDataSource(this.risks);
  selection = new SelectionModel<any>(true, []); // Para poder ordenar las columnas
  discardedRisks: Array<IRiskRating> = [];
  modifiedRiskIds: Array<number> = [];

  isLoadingExistingMap = false;

  private _subscriptions = new SubSink();

  @Input() step!: IStep;
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    // Para poder ordenar las columnas, cuando la tabla está dentro de un *ngIf
    this.dataSource.sort = sort;
  }

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private alertsSrv: HowdenAlertService,
    private confirmDialogSrv: IConfirmationDialogService,
    private uiBlockerSrv: IUIBlockerService,
    private dataRiskMapSrv: IRisksDataMapService,
    private riskSrv: IRiskService
  ) {
  }

  ngOnInit() {
    this._subscriptions.sink = this.activatedRoute.paramMap.pipe(
      tap(paramMap => {
        this.uiBlockerSrv.block();

        const id = paramMap.get('id') as string;

        if (typeof id !== 'undefined' && id !== null) {
          this.isLoadingExistingMap = true;
        }

        this.loadData(this.step.risks);

        this._subscriptions.sink = this.dataRiskMapSrv.getDataStepEmitter().subscribe((data: Array<IRiskRating>) => {
          this.reloadData(data);
        });
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  selectRisk(checkboxSelect: boolean, risk: IRiskRating, loadingSavedMap = false) {
    if (checkboxSelect) {
      // Riesgo descartado
      risk.probability = null;
      risk.impact = null;
      this.discardedRisks.push(risk);

      if (!loadingSavedMap) {
        // Guardamos datos en el servicio
        this.dataRiskMapSrv.addDiscardedRisk(risk);
        this.dataRiskMapSrv.deleteDataRiskMap(risk.riskId);
      }
      this.checkCompleteStep(risk);
    } else { // Hay que eliminar el el riesgo como descartado y poner como no modificado
      this.discardedRisks.splice(this.discardedRisks.indexOf(risk), 1);
      if (!loadingSavedMap) {
        // Guardamos datos en el servicio
        this.dataRiskMapSrv.deleteDiscardedRisk(risk);
      }
      this.modifiedRiskIds.splice(this.modifiedRiskIds.indexOf(risk.riskId), 1);
      this.step.complete = false;
    }
  }

  updateDataRiskMapProbability(risk: IRiskRating) {
    if (typeof risk.probability !== 'undefined' && risk.probability !== null) {
      if (risk.probability < 0 || risk.probability > 5) {
        this.alertsSrv.error('Error', 'El valor de la probabilidad debe estar entre 0 y 5');
        risk.probability = null;
      }
      if (this.hasValue(risk.probability) && this.hasValue(risk.impact)) {
        this.dataRiskMapSrv.updateDataRiskMap(risk);
      } else {
        this.dataRiskMapSrv.deleteDataRiskMap(risk.riskId);
      }
      this.checkCompleteStep(risk);
    }
  }

  updateDataRiskMapImpact(risk: IRiskRating) {
    if (typeof risk.impact !== 'undefined' && risk.impact !== null) {
      if (risk.impact < 0 || risk.impact > 5) {
        this.alertsSrv.warning('Error', 'El valor del impacto debe estar entre 0 y 5');
        risk.impact = null;
      }
      if (this.hasValue(risk.probability) && this.hasValue(risk.impact)) {
        this.dataRiskMapSrv.updateDataRiskMap(risk);
      } else {
        this.dataRiskMapSrv.deleteDataRiskMap(risk.riskId);
      }
      this.checkCompleteStep(risk);
    }
  }

  checkCompleteStep(risk: IRiskRating) {
    if (!this.modifiedRiskIds.find(x => x === risk.riskId)) {
      this.modifiedRiskIds.push(risk.riskId);
    }

    let complete = true;

    for (const r of this.risks) { // Comprobamos si todos los riesgos están en la lista de modificados y con probabilidad e impacto
      if (!this.modifiedRiskIds.find(x => x === r.riskId)) {
        complete = false;
        break;
      } else if (this.discardedRisks.find(x => x === r)) { // El riesgo ha sido descartado
        // nada
      } else { // El riesgo, si tiene probabilidad (o impacto) también debe tener impacto (o probabilidad)
        if ((this.hasValue(risk.probability) && !this.hasValue(risk.impact))
          || (!this.hasValue(risk.probability) && this.hasValue(risk.impact))) {
          complete = false;
          break;
        }
      }
    }

    this.step.complete = complete;
  }

  helpProbability() {
    this.dialog.open(HelpProbabilityComponent, {
      width: '80%'
    });
  }

  helpImpact() {
    this.dialog.open(HelpImpactComponent, {
      width: '600px'
    });
  }

  helpRisk(risk: IRiskRating) {
    this.dialog.open(HelpRiskComponent, {
      width: '800px',
      data: { risk }
    });
  }

  newRisk(): void {
    const inputData = { id: null };
    this.showEditDialog(inputData);
  }

  editRisk(event: IRiskRating): void {
    const inputData = { id: event.riskId };
    this.showEditDialog(inputData);
  }

  deleteRisk(event: IRiskRating) {
    const riskId = event.riskId;

    const title: string = $localize`: @@app.risks-others.search.delete.title:Borrado de Riesgo`;
    const question: string = $localize`: @@app.risks-others.search.delete.subtitle: Se borrará el riesgo, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          const subtitle: string = $localize`: @@app.risks-others.search.delete.confirm.subtitle:Finalizado Correctamente`;

          this.uiBlockerSrv.block();

          //Eliminamos el riesgo de la información del mapa
          this.dataRiskMapSrv.deleteDataRiskMap(riskId);
          this.dataRiskMapSrv.deleteDiscardedRisk(event);
          this.modifiedRiskIds.splice(this.modifiedRiskIds.indexOf(riskId), 1);

          //Damos de baja el riesgo en base de datos
          this.riskSrv.delete(riskId).pipe(
            tap({
              complete: () => {
                this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => {
                  this.uiBlockerSrv.unblock();
                  this.refreshOwnRisks();
                });
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  private hasValue(val: any): boolean {
    return typeof val !== 'undefined' && val !== null;
  }

  private loadData(risks: IRiskRating[]) {
    this.risks = risks;
    this.dataSource = new MatTableDataSource(this.risks);
  }

  private reloadData(riskRatings: Array<IRiskRating>) {
    const dataRiskSelect = riskRatings.filter(x => x.discarded === false && x.categoryId === this.step.categoryId);
    this.discardedRisks = riskRatings.filter(x => x.discarded === true && x.categoryId === this.step.categoryId);

    if (this.discardedRisks.length > 0 || dataRiskSelect.length > 0) {
      this.risks.forEach(risk => {
        if (this.discardedRisks.find(x => x.riskId === risk.riskId)) {
          this.selectRisk(true, risk, true);
        } else {
          const saveRisk = dataRiskSelect.find(r => r.riskId === risk.riskId);
          if (saveRisk) {
            risk.probability = saveRisk.probability;
            risk.impact = saveRisk.impact;
            this.modifiedRiskIds.push(risk.riskId);
            this.checkCompleteStep(risk);
          }
        }
      });
    }
  }

  private refreshOwnRisks(): void {
    this.riskSrv.getByUser().subscribe((data: IRiskRating[]) => {
      this.loadData(data);
      const ratings = this.dataRiskMapSrv.getRiskRatings(this.step.categoryId);
      if (ratings.length > 0) {
        this.reloadData(ratings);
      }
    });
  }

  private showEditDialog(inputData: any) {
    const dialogRef = this.dialog.open(EditRiskComponent, {
      minWidth: '400px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().pipe(
      tap((dialogResult?: IDialogResult<void>) => {
        if (dialogResult && dialogResult.result === DialogResultTypes.OK) {
          this.refreshOwnRisks();
        }
      })
    ).subscribe();
  }
}
