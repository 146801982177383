import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { API_PATH_APPS } from 'src/app/core/constants';
import { ILocality } from 'src/app/core/models/api';
import { ILocalityService } from '.';

@Injectable({
  providedIn: 'root'
})
export class LocalityService implements ILocalityService {
  apiName = API_PATH_APPS.API_RISK_MAP;
  basePath = 'api/locality';

  constructor(private httpClient: HowdenHttpClientService) { }

  getLocalities(): Observable<Array<ILocality>> {
    return this.httpClient.get<Array<ILocality>>(
      this.apiName,
      this.basePath
    );
  }
}
