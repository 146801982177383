import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ILocality } from 'src/app/core/models/api';

export class DownloadReportViewModel {
  private _form: FormGroup;
  private _localities: Array<ILocality> = [];
  private _mapImageBase64 = '';

  readonly C_BUSINESS_NAME = 'businessName';
  readonly C_FISCAL_CODE = 'fiscalCode';
  readonly C_CONTACT_EMAIL = 'contactEmail';
  readonly C_CONTACT_PHONE = 'contactPhone';
  readonly C_LOCALITY_ID = 'localityId';
  readonly C_PRIVACY_POLICY = 'privatePolicy';

  constructor() {
    this._form = new FormGroup({
      [this.C_BUSINESS_NAME]: new FormControl(null, [Validators.required]),
      [this.C_FISCAL_CODE]: new FormControl(null, [Validators.required]),
      [this.C_CONTACT_EMAIL]: new FormControl(null, [Validators.required]),
      [this.C_CONTACT_PHONE]: new FormControl(null, [Validators.required]),
      [this.C_LOCALITY_ID]: new FormControl(null, [Validators.required]),
      [this.C_PRIVACY_POLICY]: new FormControl(null, [Validators.required])
    });
  }

  getControl(controlName: string): AbstractControl<any, any> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._form.get(controlName)!;
  }

  get mapImageBase64(): string {
    return this._mapImageBase64;
  }
  set mapImageBase64(value: string) {
    this._mapImageBase64 = value;
  }

  get localities(): Array<ILocality> {
    return this._localities;
  }
  set localities(value: Array<ILocality>) {
    this._localities = value;
  }

  get form(): FormGroup {
    return this._form;
  }
  set form(value: FormGroup) {
    this._form = value;
  }

  get businessName(): string | null | undefined {
    return this.getControl(this.C_BUSINESS_NAME)?.value;
  }
  set businessName(value: string | null | undefined) {
    this.getControl(this.C_BUSINESS_NAME)?.setValue(value);
  }

  get fiscalCode(): string | null | undefined {
    return this.getControl(this.C_FISCAL_CODE)?.value;
  }
  set fiscalCode(value: string | null | undefined) {
    this.getControl(this.C_FISCAL_CODE)?.setValue(value);
  }

  get contactEmail(): string | null | undefined {
    return this.getControl(this.C_CONTACT_EMAIL)?.value;
  }
  set contactEmail(value: string | null | undefined) {
    this.getControl(this.C_CONTACT_EMAIL)?.setValue(value);
  }

  get contactPhone(): string | null | undefined {
    return this.getControl(this.C_CONTACT_PHONE)?.value;
  }
  set contactPhone(value: string | null | undefined) {
    this.getControl(this.C_CONTACT_PHONE)?.setValue(value);
  }

  get localityId(): number | null | undefined {
    return this.getControl(this.C_LOCALITY_ID)?.value;
  }
  set localityId(value: number | null | undefined) {
    this.getControl(this.C_LOCALITY_ID)?.setValue(value);
  }

  get privatePolicy(): boolean | null | undefined {
    return this.getControl(this.C_PRIVACY_POLICY)?.value;
  }
  set privatePolicy(value: boolean | null | undefined) {
    this.getControl(this.C_PRIVACY_POLICY)?.setValue(value);
  }
}
