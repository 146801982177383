import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HowdenAuthService } from '@howdeniberia/core-front';
import { UserRole } from 'src/app/core/enums';

@Component({
  selector: 'howden-cookie-delete',
  templateUrl: './cookie-delete.component.html',
  styleUrls: ['./cookie-delete.component.scss']
})
export class CookieDeleteComponent {
  constructor(
    private router: Router,
    private authSrv: HowdenAuthService
  ) {
  }

  returnToRiskMap() {
    if (this.authSrv.isSuperAdmin || this.authSrv.isUserAuthorized([UserRole.Manager])) {
      this.router.navigate(['management/home']);
    } else if (this.authSrv.isUserAuthorized([UserRole.Basic])) {
      this.router.navigate(['/home']);
    }
  }
}
