import { Injectable } from '@angular/core';
import { IDialogResult, IHowdenYesNoData, YesNoService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { IConfirmationDialogService } from './confirmation-dialog.contract';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService implements IConfirmationDialogService {
  constructor(private confirmDialogSrv: YesNoService) {
  }

  openHelp(title: string, subtitle: string): Observable<IDialogResult<boolean>> {
    const options = {
      title: title,
      icon: 'help',
      okActionText: $localize`:@@app.core.actions.ok:Aceptar`,
      cancelActionText: $localize`:@@app.core.actions.cancel:Cancelar`,
      question: subtitle,
      moreText: '',
      disableClose: true
    } as IHowdenYesNoData;

    return this.confirmDialogSrv.open(options);
  }

  openDanger(title: string, subtitle: string): Observable<IDialogResult<boolean>> {
    const options = {
      title: title,
      icon: 'dangerous',
      okActionText: $localize`:@@app.core.actions.ok:Aceptar`,
      cancelActionText: $localize`:@@app.core.actions.cancel:Cancelar`,
      question: subtitle,
      moreText: '',
      disableClose: true
    } as IHowdenYesNoData;

    return this.confirmDialogSrv.open(options);
  }

  openDefault(title: string, subtitle?: string): Observable<IDialogResult<boolean>> {
    const options = {
      title: title,
      subTitle: subtitle ?? $localize`:@@app.confirm.dialog.default.subtitle:Finalizada correctamente`,
      allowCancel: false,
      okActionText: $localize`:@@app.core.actions.ok:Aceptar`,
      icon: 'task_alt',
      question: '',
      moreText: '',
      disableClose: true
    } as IHowdenYesNoData;

    return this.confirmDialogSrv.open(options);
  }
}
