import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RisksEvaluationComponent } from './pages/risks-evaluation/risks-evaluation.component';

export const RiskMapEvaluationRoutes = [
  {
    path: 'risks-evaluation',
    component: RisksEvaluationComponent,
    data: {
      icon: 'edit_note',
      linkText: $localize`:@@app.route.risks-evaluation:Evaluar Riesgos`
    }
  },
  {
    path: 'risks-evaluation/map/:id',
    component: RisksEvaluationComponent,
    data: {
      icon: 'edit_note',
      linkText: $localize`:@@app.route.risks-evaluation:Evaluar Riesgos`
    }
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(RiskMapEvaluationRoutes)],
  exports: [RouterModule]
})
export class RiskMapEvaluationRoutingModule { }
