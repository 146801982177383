import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IMapRiskInfo } from 'src/app/core/models/front';
import { IRisksDataMapService } from 'src/app/core/services/core';

@Component({
  selector: 'howden-risks-selection',
  templateUrl: './risks-selection.component.html',
  styleUrls: ['./risks-selection.component.scss']
})
export class RisksSelectionComponent implements OnInit {
  public subscription: any;

  public displayedColumns: string[] = ['riskId', 'categoryDesc', 'description', 'probability', 'impact', 'order'];
  public riskSelected: Array<any> = [];
  public dataSource = new MatTableDataSource(this.riskSelected);
  public selection = new SelectionModel<any>(true, []); // Para poder ordenar las columnas

  @ViewChild(MatSort, { static: true }) sort!: MatSort; // Para poder ordenar las columnas

  constructor(private dataRiskMapSrv: IRisksDataMapService) {
  }

  ngOnInit() {
    this.subscription = this.dataRiskMapSrv.getDataRiskMapEmitter().subscribe((data: Array<IMapRiskInfo>) => {
      this.updateSelectedRisks(data);
    });
  }

  updateSelectedRisks(dataRiskMap: Array<IMapRiskInfo>) {
    this.riskSelected = [];

    dataRiskMap.forEach((x) => {
      x.risks.forEach((r) => {
        this.riskSelected.push({
          riskId: r.riskId,
          description: r.description,
          categoryDesc: r.categoryDesc,
          probability: x.probability,
          impact: x.impact,
          order: x.order,
          color: x.color
        });
      });
    });

    this.dataSource = new MatTableDataSource(this.riskSelected);
    this.dataSource.sort = this.sort;
  }
}
