import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, HowdenAlertService, IDialogResult } from '@howdeniberia/core-front';
import html2canvas from 'html2canvas';
import { ILocality } from 'src/app/core/models/api';
import { ILocalityService } from 'src/app/core/services/api/locality';
import { IMapService } from 'src/app/core/services/api/map';
import { IRisksDataMapService } from 'src/app/core/services/core';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { FileUtils } from 'src/app/shared/utils';
import { DownloadReportMapper } from '../../mappers';
import { DownloadReportViewModel } from '../../models';

@Component({
  selector: 'howden-download-report',
  templateUrl: './download-report.component.html',
  styleUrls: ['./download-report.component.scss']
})
export class DownloadReportComponent implements OnInit {
  model = new DownloadReportViewModel();

  constructor(
    public dialogRef: MatDialogRef<DownloadReportComponent>,
    private alertsSrv: HowdenAlertService,
    private uiBlockerSrv: IUIBlockerService,
    private localitySrv: ILocalityService,
    private mapSrv: IMapService,
    private dataRiskMapSrv: IRisksDataMapService
  ) {
  }

  ngOnInit() {
    this.uiBlockerSrv.block();

    let imageCode = '';
    const element = document.getElementById('divGraphMap');
    if (element) {
      // Generamos la imagen del mapa y guardamos el código de la imagen en base64
      html2canvas(element, { logging: false }).then(function (canvas: any) {
        imageCode = canvas.toDataURL('image/png');
      });
    }

    this.localitySrv.getLocalities().subscribe((localities: ILocality[]) => {
      this.model.localities = localities;
      this.model.mapImageBase64 = imageCode;

      this.uiBlockerSrv.unblock();
    });
  }

  createAndDownloadReport() {
    this.uiBlockerSrv.block();

    const map = DownloadReportMapper.mapForSave(this.model);
    const request = this.dataRiskMapSrv.getDataRiskMap(map);

    this.mapSrv.generateReport(request).subscribe(
      {
        next: (blob: Blob) => {
          this.uiBlockerSrv.unblock();
          if (!blob) {
            this.alertsSrv.error('Error', 'Se ha producido un error al generar el informe');
          } else {
            this.dataRiskMapSrv.setAlreadySaved(true);
            const attachName = FileUtils.formatFilename('RiskMap', 'pdf');
            FileUtils.downloadFile(
              attachName,
              blob
            );
            this.dialogRef.close({ result: DialogResultTypes.OK } as IDialogResult<void>);
          }
        }
      }
    );
  }
}
