import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HowdenAuthService } from '@howdeniberia/core-front';
import { UserRole } from 'src/app/core/enums';

@Component({
  selector: 'howden-initial',
  templateUrl: './initial.component.html',
  styleUrl: './initial.component.scss'
})
export class InitialComponent implements OnInit {
  constructor(
    private router: Router,
    private authSrv: HowdenAuthService
  ) {
  }

  ngOnInit() {
    if (this.authSrv.isLogued()) {
      if (this.authSrv.isSuperAdmin || this.authSrv.isUserAuthorized([UserRole.Manager])) {
        this.router.navigate(['management/map-search']);
      } else if (this.authSrv.isUserAuthorized([UserRole.Basic])) {
        this.router.navigate(['/home']);
      } else {
        // stays on
      }
    }
  }
}
