<form [formGroup]="model.form" (ngSubmit)="createAndDownloadReport()">
  <div mat-dialog-content>
    <div class="flex">
      <div class="flex f-basis--4 f-ai--center f-jc--center">
        <div class="logo"><img src="../../../../../assets/images/logo.png" /></div>
      </div>
      <div class="f-basis--8">
        <h1 i18n="@@app.risk-map.download-report.title">Descarga tu Evaluación de Riesgos</h1>
        <h3 i18n="@@app.risk-map.download-report.subtitle">Para ello sólo tendrás que introducir algunos datos de contacto</h3>
      </div>
    </div>

    <div class="flex">
      <div class="flex f-basis--4 f-ai--center f-jc--center">
        <span class="material-symbols-outlined">clarify</span>
      </div>

      <div class="f-basis--8">
        <div class="flex">
          <div class="f-basis--12">
            <mat-form-field>
              <mat-label i18n="@@app.risk-map.download-report.businessName">Razón Social</mat-label>
              <input matInput [formControlName]="model.C_BUSINESS_NAME" maxlength="50" />
            </mat-form-field>
          </div>
        </div>

        <div class="flex f-gap--10px">
          <div class="f-basis--4">
            <mat-form-field>
              <mat-label i18n="@@app.risk-map.download-report.fiscalCode">CIF</mat-label>
              <input matInput [formControlName]="model.C_FISCAL_CODE" maxlength="15"/>
            </mat-form-field>
          </div>

          <div class="f-basis--8">
            <mat-form-field>
              <mat-label i18n="@@app.risk-map.download-report.contactEmail">Correo Electrónico de Contacto</mat-label>
              <input matInput [formControlName]="model.C_CONTACT_EMAIL" maxlength="50"/>
            </mat-form-field>
          </div>
        </div>

        <div class="flex f-gap--10px">
          <div class="f-basis--6">
            <mat-form-field>
              <mat-label i18n="@@app.risk-map.download-report.contactPhone">Teléfono de Contacto</mat-label>
              <input matInput [formControlName]="model.C_CONTACT_PHONE" maxlength="15"/>
            </mat-form-field>
          </div>

          <div class="f-basis--6">
            <howden-autocomplete
              class="f-basis--6"
              i18n-label="@@app.risk-map.download-report.locality"
              label="Localidad"
              displayField="description"
              keyField="localityId"
              [selectableItems]="model.localities"
              [formControlName]="model.C_LOCALITY_ID"
            >
            </howden-autocomplete>
          </div>
        </div>

        <div class="flex f-gap--10px">
          <div class="f-basis--12">
            <mat-checkbox class="inline" [formControlName]="model.C_PRIVACY_POLICY">
              <a
                href="https://www.howdeniberia.com/politica-de-privacidad/"
                i18n="@@app.risk-map.download-report.privacyPolicy"
                title="Consulta Política de Privacidad"
                target="_blank"
                mat-menu-item
              >
                Acepto la Política de Privacidad
              </a>
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions class="btn-container flex f-ai--center f-jc--center">
    <button mat-raised-button mat-dialog-close>
      <span i18n="@@app.risk-map.download-report.returnEvaluation">Volver a la Evaluación</span>
    </button>
    <button mat-raised-button [disabled]="model.form.invalid" type="submit">
      <span i18n="@@app.risk-map.download-report.download">Descargar</span>
    </button>
  </div>
</form>
