<mat-sidenav-container fullscreen>
  <mat-toolbar class="upper">
    <span>
      <h1>Todas las cookies han sido borradas correctamente.<span>&#160;</span></h1>
    </span>
  </mat-toolbar>
  <mat-toolbar class="lower">
    <mat-toolbar-row></mat-toolbar-row>
    <mat-toolbar-row>
      <span> ¿Has cambiado de opinión? <a (click)="returnToRiskMap()">Volver al Mapa de Riesgos de Howden</a>. </span>
    </mat-toolbar-row>
    <mat-toolbar-row>
      <span> En cambio, si deseas seguir navegando sin cookies puedes hacer una <a href="https://www.google.es/"> nueva búsqueda</a>. </span>
    </mat-toolbar-row>
  </mat-toolbar>
</mat-sidenav-container>
