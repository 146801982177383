import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { API_PATH_APPS } from 'src/app/core/constants';
import { ICategory } from 'src/app/core/models/api';
import { ICategoryService } from '.';

@Injectable({
  providedIn: 'root'
})
export class CategoryService implements ICategoryService {
  apiName = API_PATH_APPS.API_RISK_MAP;
  basePath = 'api/category';

  constructor(private httpClient: HowdenHttpClientService) { }

  getCategories(): Observable<Array<ICategory>> {
    return this.httpClient.get<Array<ICategory>>(
      this.apiName,
      this.basePath
    );
  }
}
