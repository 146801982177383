<div class="flex f-gap--10px">
  <div class="f-basis--3 text-left btn-container">
    @if (!step.first) {
      <button mat-raised-button title="Paso Anterior" matStepperPrevious>
        <i class="material-symbols-outlined">keyboard_arrow_left</i>
      </button>
    }
  </div>

  <div class="f-basis--6 text-center ">
    <h3>Riesgos del Tipo {{ step.description }}</h3>
  </div>

  <div class="f-basis--3 text-right btn-container">
    @if (step.others && !isLoadingExistingMap) {
      <button mat-raised-button [disabled]="risks.length >= 10" (click)="newRisk()">
        Nuevo Riesgo
      </button>
    }
    @if (!step.last) {
      <button
        mat-raised-button
        title="Siguiente Paso"
        matStepperNext
        [disabled]="!step.complete"    >
        <i class="material-symbols-outlined">keyboard_arrow_right</i>
      </button>
    }
  </div>
</div>

<div class="flex">
  <div class="f-basis--12">
    <div class="table-container">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="riskId"
        matSortDirection="asc"
        class="mat-elevation-z8 table table-responsive"
      >
        <ng-container matColumnDef="riskId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
          <td mat-cell *matCellDef="let element">{{ element.riskId }}</td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Evento</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex f-ai--center">
              <button mat-icon-button color="primary" aria-label="helpRiesgo" title="Ejemplos" (click)="helpRisk(element)">
                <mat-icon>help</mat-icon>
              </button>
              <span>{{ element.description }}</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="probability">
          <th mat-header-cell *matHeaderCellDef>
            Probabilidad
            <button
              mat-icon-button
              color="primary"
              aria-label="helpProbabilidad"
              title="Ayuda para los valores a asignar"
              (click)="helpProbability()"
            >
              <mat-icon>help</mat-icon>
            </button>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field [style.display]="discardedRisks.indexOf(element) > -1 ? 'none' : 'block'" appearance="outline">
              <input
                matInput
                type="number"
                step="0.5"
                min="0"
                max="5"
                [(ngModel)]="element.probability"
                [ngModelOptions]="{ standalone: true }"
                (change)="updateDataRiskMapProbability(element)"
              />
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="impact">
          <th mat-header-cell *matHeaderCellDef>
            Impacto
            <button
              mat-icon-button
              color="primary"
              aria-label="helpImpacto"
              title="Ayuda para los valores a asignar"
              (click)="helpImpact()"
            >
              <mat-icon>help</mat-icon>
            </button>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field [style.display]="discardedRisks.indexOf(element) > -1 ? 'none' : 'block'" appearance="outline">
              <input
                matInput
                type="number"
                step="0.5"
                min="0"
                max="5"
                [(ngModel)]="element.impact"
                [ngModelOptions]="{ standalone: true }"
                (change)="updateDataRiskMapImpact(element)"
              />
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>Descartado</th>
          <td mat-cell *matCellDef="let element">
            @if (discardedRisks.indexOf(element) > -1) {
              <mat-checkbox
                checked="true"
                #checkbox="matCheckbox"
                (change)="selectRisk(checkbox.checked, element)"
              >
              </mat-checkbox>
            }
            @if (discardedRisks.indexOf(element) < 0) {
              <mat-checkbox
                #checkbox="matCheckbox"
                (change)="selectRisk(checkbox.checked, element)"
              >
              </mat-checkbox>
            }
          </td>
        </ng-container>
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            @if (step.others && !isLoadingExistingMap) {
              <button mat-icon-button color="primary" aria-label="editar" (click)="editRisk(element)">
                <mat-icon>create</mat-icon>
              </button>
            }
          </td>
        </ng-container>
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            @if (step.others && !isLoadingExistingMap) {
              <button mat-icon-button color="primary" aria-label="borrar" (click)="deleteRisk(element)">
                <mat-icon>delete</mat-icon>
              </button>
            }
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
      </table>
    </div>
  </div>
</div>
