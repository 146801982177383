<div class="flex f-fd--column">
 <owl-carousel-o [options]="customOptions">
 @for (avatar of avatars; track avatar ) {
    <ng-container>
      <ng-template carouselSlide [id]="avatar.id">
        <div class="flex f-ai--center f-jc--center carousel-container">
          <div class="flex f-ai--center f-jc--center f-basis--5">
            <span class="material-symbols-outlined">{{ avatar.icon }}</span>
          </div>
          <div class="flex f-ai--center f-jc--center f-basis--7 carousel-message">
            {{ avatar.message }}
          </div>
        </div>
      </ng-template>
    </ng-container>
  }
  </owl-carousel-o>

  @if (!showCookiesWarn) {
    <div class="flex f-fd--column f-ai--center margin-top-20 margin-bottom-25 btn-container">
      <button mat-raised-button (click)="goToRiskMap()">
        Realizar Autoevaluación de Riesgos
      </button>
    </div>
  } @else {
    <mat-toolbar class="cookies flex margin-top-40">
        <div class="f-basis--9 f-gap--10px f-jc--centers btn-container">
          <span>Utilizamos cookies para mejorar tu navegación, ¿De acuerdo? &nbsp;
            <a href="https://www.howdeniberia.com/politica-de-cookies/" target="_blank">Leer más </a>
          </span>&nbsp;
          <button mat-raised-button (click)="acceptCookies()">Aceptar</button>
        </div>
        <div class="f-basis--1 divSmallIcon">
          <i class="material-symbols-outlined">delete</i>
        </div>
        <div class="f-basis--2 ">
            <a (click)="deleteCookies()">Salir y borrar las cookies</a>
        </div>
    </mat-toolbar>
  }
</div>
