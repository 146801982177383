import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, HowdenAlertService, IDialogResult } from '@howdeniberia/core-front';
import { tap } from 'rxjs';
import { IRiskService } from 'src/app/core/services/api/risk';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { EditRiskMapper } from '../../mappers';
import { EditRiskViewModel } from '../../models';

@Component({
  selector: 'howden-edit-risk',
  templateUrl: './edit-risk.component.html',
  styleUrls: ['./edit-risk.component.scss']
})
export class EditRiskComponent implements OnInit {
  model = new EditRiskViewModel();

  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    public dialogRef: MatDialogRef<EditRiskComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private riskSrv: IRiskService,
    private alertSrv: HowdenAlertService
  ) {
  }

  ngOnInit(): void {
    if (this.riskId !== 'undefined' && this.riskId !== null) {
      this.uiBlockerSrv.block();
      this.riskSrv.get(this.riskId).subscribe((data) => {
        EditRiskMapper.mapForEdit(data, this.model);
        this.uiBlockerSrv.unblock();
      });
    }
  }

  saveRisk() {
    this.uiBlockerSrv.block();
    const request = EditRiskMapper.mapForUpdate(this.model);

    this.riskSrv
      .save(request)
      .pipe(
        tap((response) => {
          const msgResult = response.result as string;
          if (msgResult) {
            const title: string = $localize`: @@app.risk.save.result.warning.title:¡Advertencia!`;
            const message: string = $localize`: @@app.risk.save.result.warning.message:Riesgo no modificado:`;
            this.alertSrv.warning(title, `${message} ${msgResult}`);
          } else {
            const title: string = $localize`: @@app.risk.save.result.ok.title:Éxito!`;
            const message: string = $localize`: @@app.risk.save.result.ok.message:Riesgo guardado correctamente`;
            this.alertSrv.success(title, message);
          }
          this.uiBlockerSrv.unblock();
          this.dialogRef.close({ result: DialogResultTypes.OK } as IDialogResult<void>);
        })
      )
      .subscribe();
  }

  private get riskId(): string | null {
    return this.inputData.id as string | null;
  }
}
