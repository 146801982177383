import { IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { IRisk, IRiskRating } from 'src/app/core/models/api';

export abstract class IRiskService {
  abstract getByUser(): Observable<Array<IRiskRating>>;
  abstract get(riskId: string): Observable<IRisk>;
  abstract save(resquest: IRisk): Observable<IResponse<string>>;
  abstract delete(riskId: number): Observable<IResponse<boolean>>;
}
