import { IMap } from 'src/app/core/models/api';
import { DownloadReportViewModel } from '../models';

export abstract class DownloadReportMapper {
  static mapForSave(source: DownloadReportViewModel): IMap {
    return {
      fiscalCode: source.fiscalCode,
      businessName: source.businessName,
      contactEmail: source.contactEmail,
      contactPhone: source.contactPhone,
      localityId: source.localityId,
      mapImageBase64: source.mapImageBase64
    } as IMap;
  }
}
