import { FormControl, FormGroup } from '@angular/forms';
import {
  ColumnAligment,
  ColumnPipeTypes,
  IHowdenColumnConfig,
  ITableButtonConfig,
  ITableEventData,
  TableButtonColors
} from '@howdeniberia/core-front';
import { IMapFilter } from 'src/app/core/models/api';
import { IMapViewModel } from '.';

export class MapSearchViewModel {
  private _filterForm: FormGroup;
  private _searchRequest: IMapFilter;
  private _sortedBy?: string;
  private _sortDirection?: string;
  private _length = 0;

  readonly C_DATE_FROM = 'dateFrom';
  readonly C_DATE_TO = 'dateTo';
  readonly C_FISCAL_CODE = 'fiscalCode';
  readonly C_BUSINESS_NAME = 'businessName';
  readonly C_CONTACT_EMAIL = 'contactEmail';
  readonly C_CREATOR_USER = 'creatorUser';

  constructor() {
    this._filterForm = new FormGroup({
      [this.C_DATE_FROM]: new FormControl(null, []),
      [this.C_DATE_TO]: new FormControl(null, []),
      [this.C_FISCAL_CODE]: new FormControl('', []),
      [this.C_BUSINESS_NAME]: new FormControl('', []),
      [this.C_CONTACT_EMAIL]: new FormControl(null, []),
      [this.C_CREATOR_USER]: new FormControl('', [])
    });

    this._searchRequest = {
      pageNumber: 0,
      pageSize: 10,
      sortedBy: this._sortedBy,
      sortDirection: this.sortDirection
    } as IMapFilter;
  }

  data: IMapViewModel[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'fiscalCode',
      fieldHeader: $localize`:@@app.map.search.fiscalCode.column:CIF`,
      canSort: true,
      width: '10%'
    },
    {
      fieldName: 'businessName',
      fieldHeader: $localize`:@@app.map.search.businessName.column:Razón Social`,
      canSort: true,
      width: '30%'
    },
    {
      fieldName: 'contactEmail',
      fieldHeader: $localize`:@@app.map.search.contactEmail.column:Email Contacto`,
      canSort: true,
      width: '20%'
    },
    {
      fieldName: 'creatorUser',
      fieldHeader: $localize`:@@app.map.search.creatorUser.column:Usuario Creador`,
      canSort: true,
      width: '20%'
    },
    {
      fieldName: 'creatorDate',
      fieldHeader: $localize`:@@app.map.search.creatorDate.column:Fecha Creación`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE,
      aligment: ColumnAligment.CENTER,
      width: '10%'
    }
  ];

  buttons: Array<ITableButtonConfig> = [
    {
      name: 'edit',
      color: TableButtonColors.INFO,
      icon: 'edit',
      tooltip: $localize`:@@app.map.search.actions.edit.tooltip:Editar Mapa`
    },
    {
      name: 'download',
      color: TableButtonColors.ACCENT,
      icon: 'download',
      tooltip: $localize`:@@app.map.search.actions.download.tooltip:Descargar Informe`,
      isDisabled: (row: any): boolean => (typeof row.fiscalCode === 'undefined' || row.fiscalCode === null || row.fiscalCode === '')
    }
  ];

  get length(): number {
    return this._length;
  }

  set length(length: number) {
    this._length = length;
  }

  get sortedBy(): string | undefined {
    return this._sortedBy;
  }

  get sortDirection(): string | undefined {
    return this._sortDirection;
  }

  get searchRequest(): IMapFilter {
    return this._searchRequest;
  }

  get filterForm(): FormGroup {
    return this._filterForm;
  }

  set filterForm(value: FormGroup) {
    this._filterForm = value;
  }

  get dateFrom(): Date | null | undefined {
    return this._filterForm.get(this.C_DATE_FROM)?.value;
  }
  set dateFrom(value: Date | null | undefined) {
    this._filterForm.get(this.C_DATE_FROM)?.setValue(value);
  }

  get dateTo(): Date | null | undefined {
    return this._filterForm.get(this.C_DATE_TO)?.value;
  }
  set dateTo(value: Date | null | undefined) {
    this._filterForm.get(this.C_DATE_TO)?.setValue(value);
  }

  get fiscalCode(): string | null | undefined {
    return this._filterForm.get(this.C_FISCAL_CODE)?.value;
  }
  set fiscalCode(value: string | null | undefined) {
    this._filterForm.get(this.C_FISCAL_CODE)?.setValue(value);
  }

  get businessName(): string | null | undefined {
    return this._filterForm.get(this.C_BUSINESS_NAME)?.value;
  }
  set businessName(value: string | null | undefined) {
    this._filterForm.get(this.C_BUSINESS_NAME)?.setValue(value);
  }

  get contactEmail(): string | null | undefined {
    return this._filterForm.get(this.C_CONTACT_EMAIL)?.value;
  }
  set contactEmail(value: string | null | undefined) {
    this._filterForm.get(this.C_CONTACT_EMAIL)?.setValue(value);
  }

  get creatorUser(): string | null | undefined {
    return this._filterForm.get(this.C_CREATOR_USER)?.value;
  }
  set creatorUser(value: string | null | undefined) {
    this._filterForm.get(this.C_CREATOR_USER)?.setValue(value);
  }

  updateServerSideConfig(event: ITableEventData): void {
    this.searchRequest.pageNumber = event.pageIndex;
    this.searchRequest.pageSize = event.pageSize;
    this.searchRequest.sortedBy = event.fieldSorted;
    this.searchRequest.sortDirection = event.order;

    this.updateServerSideFilters();
  }

  updateServerSideFilters(): void {
    this.searchRequest.dateFrom = this.dateFrom;
    this.searchRequest.dateTo = this.dateTo;
    this.searchRequest.fiscalCode = this.fiscalCode;
    this.searchRequest.businessName = this.businessName;
    this.searchRequest.contactEmail = this.contactEmail;
    this.searchRequest.creatorUser = this.creatorUser;
  }
}
