import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl } from '@angular/material/paginator';
import {
  HowdenAlertsModule,
  HowdenCoreModule,
  HowdenErrorPagesModule,
  HowdenPageTitleModule,
  HowdenUserService,
  IHowdenEnvironment,
  IPackageJsonWrapper,
  NotificationsService,
  getPaginatorIntl
} from '@howdeniberia/core-front';
import 'hammerjs';
import 'moment/locale/es';
import { NgxEchartsModule } from 'ngx-echarts';
import { ApiConfigurations } from 'src/environments/api-configurations';
import { environment } from 'src/environments/environment';
import packageJson from '../../package.json';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HelpModule } from './features/help/help.module';
import { HomePageModule } from './features/homepage/homepage.module';
import { ManagementModule } from './features/management/management.module';
import { RiskMapEvaluationModule } from './features/risk-map-evaluation/risk-map-evaluation.module';

registerLocaleData(localeEs, 'es');

function getLocaleId(userSrv: HowdenUserService) {
  return userSrv?.userData?.selectedLanguage ?? 'es';
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
    AppRoutingModule,
    HomePageModule,
    HelpModule,
    RiskMapEvaluationModule,
    ManagementModule,
    HowdenPageTitleModule,
    HowdenErrorPagesModule,
    HowdenAlertsModule,
    HowdenCoreModule.forRoot(environment as IHowdenEnvironment, packageJson as IPackageJsonWrapper, ApiConfigurations)
  ],
  providers: [
    NotificationsService,
    { provide: LOCALE_ID, useFactory: getLocaleId, deps: [HowdenUserService] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: { formFieldAppearance: 'fill' } },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true, autoFocus: true, restoreFocus: true } },
    { provide: MatPaginatorIntl, useValue: getPaginatorIntl() }
  ]
})
export class AppModule { }
