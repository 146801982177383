import { IMapRating } from 'src/app/core/models/api';
import { IRiskRatingViewModel } from '../models';

export abstract class RiskRatingMapper {
  static mapForSearch(source: IMapRating): IRiskRatingViewModel {
    return {
      mapId: source.mapId,
      mapDescription: source.mapDescription,
      mapUserCreator: source.mapUserCreator,
      mapDateCreator: source.mapDateCreator,
      categoryDesc: source.categoryDesc,
      riskId: source.riskId,
      description: source.description,
      probability: source.probability,
      impact: source.impact
    } as IRiskRatingViewModel;
  }
}
