import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MapSearchComponent } from './map-search/pages/map-search/map-search.component';
import { RiskRatingSearchComponent } from './risk-rating-search/pages/risk-rating-search/risk-rating-search.component';


export const ManagementRoutes = [
  {
    path: 'map-search',
    component: MapSearchComponent,
    title: $localize`:@@app.route.management.map-search: Mapas Realizados`,
    data: {
      icon: 'description',
      linkText: $localize`:@@app.route.management.map-search.menu: Informes`
    }
  },
  {
    path: 'risk-rating-search',
    component: RiskRatingSearchComponent,
    title: $localize`:@@app.route.management.risk-rating-search: Valoraciones Riesgos`,
    data: {
      icon: 'task',
      linkText: $localize`:@@app.route.management.risk-rating-search.menu: Valoraciones`
    }
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(ManagementRoutes)],
  exports: [RouterModule]
})
export class ManagementRoutingModule { }
