<div class="flex">
  <div class="f-basis--12">
    <div class="table-container margin-top-25">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="orden"
        matSortDirection="desc"
        class="mat-elevation-z8 table table-responsive"
      >
        <ng-container matColumnDef="riskId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
          <td mat-cell *matCellDef="let element">{{ element.riskId }}</td>
        </ng-container>
        <ng-container matColumnDef="categoryDesc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Categoría</th>
          <td mat-cell *matCellDef="let element">{{ element.categoryDesc }}</td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Evento</th>
          <td mat-cell *matCellDef="let element">{{ element.description }}</td>
        </ng-container>
        <ng-container matColumnDef="probability">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Probabilidad</th>
          <td mat-cell *matCellDef="let element" class="text-center">{{ element.probability }}</td>
        </ng-container>
        <ng-container matColumnDef="impact">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Impacto</th>
          <td mat-cell *matCellDef="let element" class="text-center">{{ element.impact }}</td>
        </ng-container>
        <ng-container matColumnDef="order">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let element" class="text-center">
            <i class="material-symbols-outlined" [style.color]="element.color">stop_circle</i>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>
