import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CookieManagerService, ICookieManagerService } from 'src/app/core/services/ui';
import { HomePageRoutingModule } from './homepage-routing.module';
import { CookieDeleteComponent } from './pages/cookie-delete/cookie-delete.component';
import { HomeComponent } from './pages/home/home.component';

@NgModule({
  declarations: [
    HomeComponent,
    CookieDeleteComponent
  ],
  imports: [
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    CarouselModule,
    HomePageRoutingModule
  ],
  providers: [
    { provide: ICookieManagerService, useClass: CookieManagerService }
  ]
})
export class HomePageModule { }
