import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CookieDeleteComponent } from './pages/cookie-delete/cookie-delete.component';
import { HomeComponent } from './pages/home/home.component';

export const HomePageRoutes = [
  {
    path: 'home',
    component: HomeComponent,
    data: {
      icon: 'home',
      linkText: $localize`:@@app.route.home.carousel:Inicio`
    }
  },
  {
    path: 'cookie-delete',
    component: CookieDeleteComponent,
    data: {}
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(HomePageRoutes)],
  exports: [RouterModule]
})
export class HomePageRoutingModule { }
