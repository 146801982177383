<h1 mat-dialog-title>Impactos</h1>
<div mat-dialog-content class="table-container">
  <table mat-table [dataSource]="dataSource" class="table">
    <ng-container matColumnDef="clasification">
      <th mat-header-cell *matHeaderCellDef>Clasificación</th>
      <td mat-cell *matCellDef="let element">{{ element.clasification }}</td>
    </ng-container>
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="text-center">{{ element.value }}</td>
    </ng-container>
    <ng-container matColumnDef="quantification">
      <th mat-header-cell *matHeaderCellDef>Cuantificación</th>
      <td mat-cell *matCellDef="let element">{{ element.quantification }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<div mat-dialog-actions class="btn-container flex f-ai--center f-jc--center">
  <button mat-raised-button mat-dialog-close>Cerrar</button>
</div>
