import { Component } from '@angular/core';
import { IHelpProbabilityElement } from 'src/app/core/models/front';
import { IHelpDataService } from 'src/app/core/services/core';

@Component({
  selector: 'howden-help-probability',
  templateUrl: './help-probability.component.html',
  styleUrls: ['./help-probability.component.scss']
})
export class HelpProbabilityComponent {
  public dataSource: IHelpProbabilityElement[] = [];
  public displayedColumns: string[] = ['clasification', 'value', 'frequency', 'example'];

  constructor(private helpDataSrv: IHelpDataService) {
    this.dataSource = this.helpDataSrv.getDataHelpProbability();
  }
}
