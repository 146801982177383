import { Injectable } from '@angular/core';
import { HowdenHttpClientService, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { API_PATH_APPS } from 'src/app/core/constants';
import { IRisk, IRiskRating } from 'src/app/core/models/api';
import { IRiskService } from '.';

@Injectable({
  providedIn: 'root'
})
export class RiskService implements IRiskService {
  apiName = API_PATH_APPS.API_RISK_MAP;
  basePath = 'api/risk';

  constructor(private httpClient: HowdenHttpClientService) { }

  getByUser(): Observable<Array<IRiskRating>> {
    return this.httpClient.get<Array<IRiskRating>>(
      this.apiName,
      `${this.basePath}/byUser`
    );
  }

  get(id: string): Observable<IRisk> {
    return this.httpClient.get<IRisk>(
      this.apiName,
      `${this.basePath}/${id}`
    );
  }

  save(request: IRisk): Observable<IResponse<string>> {
    return this.httpClient.post<IResponse<string>>(
      this.apiName,
      this.basePath,
      request
    );
  }

  delete(id: number): Observable<IResponse<boolean>> {
    return this.httpClient.delete<IResponse<boolean>>(
      this.apiName, `${this.basePath}/${id}`
    );
  }
}
