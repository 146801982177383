import { EnviromentZone, HowdenLoginBehaviour, HowdenLoginKind, IHowdenEnvironment, LogLevel, TENANT_TYPES, Theme } from '@howdeniberia/core-front';

export const environment = {
  zone: EnviromentZone.PREHOWDENGROUP,
  appIdSecurity: 12, //Es necesario establecer el Id de la aplicación asignado en Howden Security
  emailError: 'desarrollo@howdengroup.com',
  loginBehaviour: HowdenLoginBehaviour.REDIRECT,
  loginKind: HowdenLoginKind.EXTERNAL,
  howdenLoginUrl: 'https://how-loginb2c.app-staging.howdeniberia.com',
  externalAppTitle: 'Mapa de Riesgos',
  fixedTenant: TENANT_TYPES.howdeniberia, //Al fijar el tenant ya no se muestra la empresa del usuario
  fixedTheme: Theme.LIGHT,
  logLevel: LogLevel.VERBOSE,
  logoDestination: ''
} as IHowdenEnvironment;
